import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/Rx';

@Injectable()
export class AlbumsService {

  constructor(private http: HttpClient) {}

  /**
   * Get All Albums
   */
  getAlbumsList(){
    return this.http.get(`${environment.shareViewApiUrl}/albums`)
  }

  /**
   * get Album that matches to id
   */
  getAlbum(album_id : string){
    return this.http.get(`${environment.shareViewApiUrl}/album/${album_id}`)
  } 

  /**
   * get Nb Albums
   */
  getNbAlbums(){
    return this.http.get(`${environment.shareViewApiUrl}/albums/count`)
  }

  /**
   * get album's shared user
   * @param id 
   */
  getUsersShared(id){
    return this.http.get(`${environment.shareViewApiUrl}/album/${id}/share/list`)
 }

  /**
   * Delete user that matches to id
   * @param album_id:String
   */
  deleteAlbum(album_id: string){
    return this.http.delete(`${environment.shareViewApiUrl}/album/${album_id}`);
  } 

  deteSubscriptions(){
    return this.http.delete('http://localhost:8081');
  }
  
}

