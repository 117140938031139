import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from '../models/subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/Rx';

@Injectable()
export class SubscriptionsService {

  constructor(private http: HttpClient) {}

  /**
   * Get All Subscription
   */
  getSubscriptionsList(){
    return this.http.get(`${environment.shareViewApiUrl}/subscriptions`)
  }

  /**
   * Create subscription for user user_id
   * @param subscription: Subscription
   */
  createSubscription(user_id: number, subscription: Subscription){
    let body = new URLSearchParams();

    body.set('expired_at', subscription.expired_at);
    body.set('label', subscription.label.toString());
    body.set('max_album', subscription.max_album.toString());
    body.set('max_space', subscription.max_space.toString());
    body.set('unlimited', subscription.unlimited.toString());

    return this.http.post(`${environment.shareViewApiUrl}/user/${user_id}/subscription`, body.toString());  
  }

  /**
   * get User subscription that matches to user_id
   */
  getSubscription(user_id : string){
    return this.http.get(`${environment.shareViewApiUrl}/user/${user_id}/subscription`)
  }

  /**
   * delete Subscription that matches to user_id
   */
  deleteSubscription(user_id : string){
    return this.http.delete(`${environment.shareViewApiUrl}/user/${user_id}/subscription`)
  } 

  /**
   * get Nb Subscriptions
   */
  getNbSubscription(){
    return this.http.get(`${environment.shareViewApiUrl}/subscriptions/count`)
  }

  /**
   * Updates subscriprion  that matches to id
   * @param subscription:Subscription
   */
  updateSubscription(subscription : Subscription){
    let body = new URLSearchParams();
    let unlimited = +subscription.unlimited == 0 ? false : true 
    body.set('label', subscription.label);
    body.set('expired_at', subscription.expired_at);
    body.set('max_album', subscription.max_album.toString());
    body.set('max_space', subscription.max_space.toString());
    body.set('unlimited', unlimited.toString());

    return this.http.put(`${environment.shareViewApiUrl}/subscription/${subscription.id}`, body.toString())
  }
  
}
