  
  export var settings = {
    mode: 'external',
    hideSubHeader: false,  
    columns: {
      firstname: {
        title: 'Prénom',
      },
      lastname: {
        title: 'Nom',
      },
      username: {
        title: 'Pseudo',
      },
      email: {
        title: 'Email',
      },
    },
    attr: {
      class: "table table-responsive"
    },
    actions : {
        position : 'right',
        add : false,
        edit: false,
        delete: false,
        custom : [
            {
                name: 'edit',
                title: '<i class="ft-edit-2 info font-medium-1 mr-2"></i>'
            },
            {
                name: 'delete',
                title: '<i class="ft-x danger font-medium-1 mr-2"></i>'
            },
            {
                name: 'banish',
                title: '<i class="icon-user-unfollow"></i>'
            }
        ]
    },
    noDataMessage: 'Pas de résultats',
    pager: {
        perPage: 50
    }
  };
  
  