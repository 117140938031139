export class User {
    id: number;
    firstname: string;
    lastname: string;
    name: string;
    username: string;
    email: string;
    phone: string;
    password: string;
    password_repeat: string;
    facebook_token: string;
    is_banished: boolean;

    constructor(
        name?: string, 
        username?: string,
        email?: string,
        phone?: string,
        password?: string,
        password_repeat?: string,
        facebook_token?: string,
        is_banished?: boolean
    ) {}
}
