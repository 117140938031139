import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/Rx';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {}

  /**
   * Get All Users
   */
  getUsersList(){
    return this.http.get(`${environment.shareViewApiUrl}/users`) //, {observe: 'response'}
  }

  /**
   * Get Nb Users
   */
  getNbUsers(){
    return this.http.get(`${environment.shareViewApiUrl}/users/count`) //, {observe: 'response'}
  }

  /**
   * get User that matches to id
   */
  getUser(user_id : string){
    return this.http.get(`${environment.shareViewApiUrl}/user/${user_id}`)
  } 
  
  /**
   * Create user that matches to id
   * @param user:User
   */
  createUser(user: User){
    let body = new URLSearchParams();

    body.set('firstname', user.firstname);
    body.set('lastname', user.lastname);
    body.set('username', user.username);
    body.set('email', user.email);
    body.set('password', user.password);
    body.set('password_repeat', user.password_repeat);
    body.set('facebook_token', user.facebook_token);
    body.set('phone', user.phone);

    return this.http.post(`${environment.shareViewApiUrl}/users`, body.toString());  
  }

  /**
   * Updates user that matches to id
   * @param user:User
   */
  updateUser(user : User){
    let body = new URLSearchParams();
    body.set('firstname', user.firstname);
    body.set('lastname', user.lastname);
    body.set('username', user.username);
    body.set('email', user.email);
    body.set('facebook_token', user.facebook_token);
    body.set('phone', user.phone);
    body.set('scope', 'full');

    if(user.password !== null && user.password_repeat !== null){
      body.set('password', user.password);
      body.set('password_repeat', user.password_repeat);
    }

    return this.http.put(`${environment.shareViewApiUrl}/user/${user.id}`, body.toString())
  }

  /**
   * Delete user that matches to id
   * @param user_id:String
   */
  deleteUser(user_id: string){
    return this.http.delete(`${environment.shareViewApiUrl}/user/${user_id}`);
  }

  /**
   * Banish user that matches to id
   * @param user_id:String
   */
  banishUser(user_id: string){
    let body = new URLSearchParams();
    body.set('scope', 'full');
    return this.http.put(`${environment.shareViewApiUrl}/user/${user_id}/banish`, body.toString());
  }
  
}
