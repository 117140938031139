import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';

@Injectable()
export class MediasService {

  constructor(private http: HttpClient) {}

  /**
   * Delete Media 
   */
  deleteMedia(album_id, media_id){
    return this.http.delete(`${environment.shareViewApiUrl}/album/${album_id}/media/${media_id}`); 
  }

  /**
   * get Media that matches to id
   */
  getMedia(album_id: number, media_id : number){
    return this.http.get(`${environment.shareViewApiUrl}/album/${album_id}/media/${media_id}`); //, {responseType:'blob'}
  } 

}  